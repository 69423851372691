interface TEnvironment {
  appVersion: string;
  butterCMSToken: string;
  commitHash: string;
  fontAwesomeApiUrl: string;
  geolocationApi: string;
  gridApi: string;
  grimmeWebsiteUrl: string;
  insightsKey: string;
  jobListApiUrl: string;
  jobResourcesApiUrl: string;
  myGrimmeApi: string;
  newsletterSubscriptionApiUrl: string;
  production: boolean;
  projectName: string;
  registrationLink: string;
  revalidationTime: number;
  siteUrl: string;
  translationApi: string;
}

export const environment: TEnvironment = {
  appVersion: process.env.NEXT_PUBLIC_NPM_PACKAGE_VERSION,
  butterCMSToken: process.env.NEXT_PUBLIC_BUTTERCMS_API_TOKEN,
  commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH,
  fontAwesomeApiUrl: process.env.NEXT_PUBLIC_FONT_AWESOME_API,
  geolocationApi: process.env.NEXT_PUBLIC_GEOLOCATION_URI,
  gridApi: process.env.NEXT_PUBLIC_GRID_API,
  grimmeWebsiteUrl: process.env.NEXT_PUBLIC_GRIMME_WEBSITE_URL,
  insightsKey:
    process.env.NEXT_PUBLIC_APP_INSIGHTS_KEY ||
    '88160bb0-a582-45b4-b857-4e325899bb3c',
  jobListApiUrl:
    process.env.NEXT_PUBLIC_JOB_LIST_API_URL ||
    'https://jobs-backend.azurewebsites.net/api/v1/jobs',
  jobResourcesApiUrl:
    process.env.NEXT_PUBLIC_JOB_RESOURCES_API_URL ||
    'https://jobs-backend.azurewebsites.net/api/v1/resources',
  myGrimmeApi: process.env.NEXT_PUBLIC_MYGRIMME_API,
  newsletterSubscriptionApiUrl:
    process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_API_URL,
  production: process.env.NODE_ENV === 'production',
  projectName: process.env.NEXT_PUBLIC_NPM_PACKAGE_NAME,
  registrationLink: process.env.NEXT_PUBLIC_REGISTRATION_LINK,
  revalidationTime: parseInt(process.env.NEXT_PUBLIC_REVALIDATION_TIME),
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
  translationApi:
    process.env.TRANSLATIONS_API_URL ||
    'https://my-grimme-translations-tst.azurewebsites.net/api',
};
