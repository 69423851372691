import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  withNotificationsReducer,
} from '@grimme/components';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { configureStore } from '@reduxjs/toolkit';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Provider as ReduxProvider } from 'react-redux';
import { GDAPProvider } from './gdap';
import { SentryProvider } from './sentry/sentry';
import { SWRProvider } from './swr';

const store = configureStore({
  reducer: withNotificationsReducer({}),
});

interface ProviderProps {
  children?: React.ReactElement[] | React.ReactElement;
}

export function Providers({ children }: ProviderProps) {
  const { i18n, t } = useTranslation();
  const { locale } = useRouter();

  return (
    <ComponentsConfigProvider language={i18n.language} t={t}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GDAPProvider>
            <SentryProvider>
              <SWRProvider>
                <LocalizationProvider
                  adapterLocale={locale}
                  dateAdapter={AdapterLuxon}
                >
                  <GrimmeComponentsProvider>
                    {children}
                  </GrimmeComponentsProvider>
                </LocalizationProvider>
              </SWRProvider>
            </SentryProvider>
          </GDAPProvider>
        </ThemeProvider>
      </ReduxProvider>
    </ComponentsConfigProvider>
  );
}
