import { AnalyticsProvider, useUserCentricsConsent } from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import { PropsWithChildren } from 'react';
import { environment } from 'environment';

export function GDAPProvider({ children }: PropsWithChildren<unknown>) {
  const { commitHash, insightsKey, projectName, appVersion } = environment;

  const { data } = useSession();
  const isAiConsentGiven = useUserCentricsConsent('Azure Application Insights');

  return (
    <AnalyticsProvider
      instrumentationKey={insightsKey}
      isConsentGiven={isAiConsentGiven}
      defaultEnvelopeData={{
        commit: commitHash,
        project: projectName,
        version: appVersion,
      }}
      username={data?.user?.email}
    >
      {children}
    </AnalyticsProvider>
  );
}
