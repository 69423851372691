import { useUserCentricsConsent } from '@grimme/gdap';
import * as Sentry from '@sentry/nextjs';
import { PropsWithChildren, useEffect } from 'react';

export function SentryProvider({ children }: PropsWithChildren<unknown>) {
  const isSentryConsentGiven = useUserCentricsConsent('Sentry');

  useEffect(() => {
    Sentry.getCurrentHub().getClient().getOptions().enabled =
      isSentryConsentGiven;
  }, [isSentryConsentGiven]);

  return <>{children}</>;
}
