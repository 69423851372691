//this needs to be on top
import './globals.css';

import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import '@grimme/buttery/styles.css';
import '@grimme/ui-components/styles.css';
import { appWithTranslation, useTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import createEmotionCache from 'utils/createEmotionCache';
import { Providers } from 'utils/providers';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function CustomApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) {
  // Get the current locale to use in UserCentrics
  const { i18n, t } = useTranslation();

  return (
    <CacheProvider value={emotionCache}>
      <Script
        async
        data-language={i18n.language}
        id="ozysu2xCT"
        src="https://app.usercentrics.eu/latest/main.js"
        type="application/javascript"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P97H6VF')`,
        }}
        data-usercentrics="Google Tag Manager"
        id="gtm-script"
        strategy="afterInteractive"
        type="text/plain"
      />
      <Head>
        <meta content="initial-scale=1, width=device-width" name="viewport" />
        <link href="/favicon.ico" rel="icon" sizes="any" />
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" />
        <link href="/manifest.webmanifest" rel="manifest" />
      </Head>
      <DefaultSeo
        defaultTitle={t('myGRIMME_group_title', 'GRIMME Group')}
        description={t('myGRIMME_group_description')}
      />
      <Providers>
        <Component {...pageProps} />
      </Providers>
    </CacheProvider>
  );
}

export default appWithTranslation(CustomApp, nextI18NextConfig);
