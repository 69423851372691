const HttpBackend = require('i18next-http-backend/cjs');
const locales = require('./locales.json');

module.exports = {
  backend: {
    loadPath: `${process.env.TRANSLATIONS_API_URL}/api/translations/{{ns}}?language={{lng}}`,
    reloadInterval: typeof window !== 'undefined' ? false : 60 * 60 * 1000, // Reload translations every hour on server
    request: async function (_, url, __, callback) {
      // TODO: Move all the translations out of the components library and use next-translate instead of next-i18next in
      // the apps.
      try {
        const baseUrl = new URL(`${process.env.TRANSLATIONS_API_URL}`);
        baseUrl.pathname = `/api/translations`;

        const language = url.split('?language=')[1];

        const defaultNsKeys = await fetch(url).then((res) => res.json());
        const userMenuKeys = await fetch(
          `${baseUrl}/myGRIMME_usersettings?language=${language}`,
        ).then((res) => res.json());
        const bannerKeys = await fetch(
          `${baseUrl}/myGRIMME_banner_already_registered?language=${language}`,
        ).then((res) => res.json());
        const loginKey = await fetch(
          `${baseUrl}/myGRIMME_login?language=${language}`,
        ).then((res) => res.json());
        const logoutKey = await fetch(
          `${baseUrl}/myGRIMME_logout?language=${language}`,
        ).then((res) => res.json());

        const result = {
          data: {
            ...defaultNsKeys,
            ...userMenuKeys,
            ...bannerKeys,
            ...loginKey,
            ...logoutKey,
          },
          status: 200,
        };

        callback(null, result);
      } catch (e) {
        callback(e, null);
      }
    },
  },
  defaultNS: 'myGRIMME_group',
  i18n: {
    defaultLocale: 'default',
    // Introduce a "default" locale to use as default.
    // If we get a request on that "default" locale, we can detect
    // it in the _middleware.ts and redirect to the correct locale.
    //
    // This allows us to always have the locale in the url.
    // For more info, see _middleware.ts
    locales: ['default', ...locales.map((v) => v.value)],
  },
  locales: ['default', ...locales.map((v) => v.value)],
  ns: ['myGRIMME_group'],
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [] : [HttpBackend], // Don't load translations on client
};
